<template>
  <div class="suivi-acomptes">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ">
          <div class="label-box-style">
            <span class="title-tabel">Utilisateur</span>
          </div>
          <SelectComponent
            :options="[{ full_name: 'Tous', id: null }, ...computedGetUserTh]"
            :value="user"
            label="full_name"
            champName="user"
            filterName="user"
            :track-by="'text'"
            :max-heigh="130"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style bg-select-vue "
            :change="handleChangeUser"
          />
        </div>
        <div class="box-label-champ ml-3 mr-3">
          <div class="label-box-style w-103-px p-4-5">
            <span class="title-tabel">Numéro dossier</span>
          </div>
          <input
            @input="debounceInput"
            v-model="search"
            class="search-input"
            placeholder="Numéro dossier"
          />
        </div>

        <div v-if="getLoadingSuiviAcomptes" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="error-message ml-2">
          <div v-if="getErrorSuiviAcomptes" class="error">
            <ul v-if="Array.isArray(getErrorSuiviAcomptes)" class="mb-0">
              <li v-for="(e, index) in getErrorSuiviAcomptes" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorSuiviAcomptes }}</div>
          </div>
        </div>
        <div class="box-end-position">
          <b-button
            v-if="computedCantUpdate"
            size="sm"
            variant="light"
            class="button-export-style  ml-2"
            v-b-modal.addAcompteModal
            @click="handleClickModalAcompte"
            >Ajouter un acompte</b-button
          >
        </div>
        <b-modal
          ref="addAcompteModal"
          id="addAcompteModal"
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal()"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter un acompte</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addAcompteModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitAcompte"
                class="form-modal-custom-style"
              >
                <div class="ModelCol">
                  <b-form-group
                    label="Filiale"
                    label-for="filiale"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      id="filiale"
                      v-model="accountToAdd.filiale"
                      :options="getSettingFilialesTh"
                      required
                      text-field="name"
                      value-field="name"
                      class="b-form-select-raduis "
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    label="Année"
                    label-for="year"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-select
                      id="year"
                      v-model="accountToAdd.year"
                      :options="ListYear"
                      required
                      text-field="text"
                      value-field="value"
                      class="b-form-select-raduis "
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Numéro de dossier"
                    label-for="num-dossier-accompte"
                    class="input-modal-champ mb-0"
                  >
                    <b-form-input
                      autocomplete="off"
                      id="num-dossier-accompte"
                      v-model="accountToAdd.numero_dossier"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group
                  label="Acompte"
                  label-for="acompte"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="acompte"
                    v-model="accountToAdd.amount"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Nom"
                  label-for="name"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="name"
                    v-model="accountToAdd.nom"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Prénom"
                  label-for="las_name"
                  class="input-modal-champ mb-0"
                >
                  <b-form-input
                    autocomplete="off"
                    id="las_name"
                    v-model="accountToAdd.prenom"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Utilisateur"
                  label-for="user-acompte"
                  class="vue-select-modal-champ "
                >
                  <multiselect
                    v-model="accountToAdd.user"
                    :options="computedGetUserTh"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="true"
                    label="full_name"
                    track-by="id"
                    :preselect-first="false"
                    :showLabels="false"
                    required
                    :loading="getloadingSelectUserAcount"
                    class="select-vue-component-style mb-4"
                  >
                    <span slot="noResult">Aucune utilisateur trouvé.</span>
                    <span slot="noOptions">Aucune utilisateur trouvé.</span>
                  </multiselect>
                </b-form-group>

                <div class="actionModel">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style mt-3">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="body-box-rapport body-Suivi-acompte-th">
      <div class="table-rapport-style   w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style tableSuiviAcompteTh  table-header-regie"
          :items="getSuiviAcomptesTable"
          :fields="fields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(actions)="data">
            <font-awesome-icon
              v-if="checkPermission($options.name)"
              icon="trash"
              class="trash-style"
              @click="handleDeleteModal(data.item)"
              v-b-modal.deleteAcompteModal
            />
          </template>
          <template v-slot:cell(numero_dossier)="data">
            {{ data.item.numero_dossier }}
          </template>
          <template v-slot:cell(nom)="data">
            <EditableInput
              :editable="computedCantUpdate"
              :value="data.item.nom"
              :item="data.item"
              champName="nom"
              type="text"
              :updateFunction="updateAcompteTh"
            ></EditableInput>
          </template>
          <template v-slot:cell(prenom)="data">
            <EditableInput
              :editable="computedCantUpdate"
              :value="data.item.prenom"
              :item="data.item"
              champName="prenom"
              type="text"
              :updateFunction="updateAcompteTh"
            ></EditableInput>
          </template>
          <template v-slot:cell(commercial)="data">
            {{ data.item.employee.full_name }}
          </template>

          <template v-slot:cell(amount)="data">
            <EditableInput
              :editable="computedCantUpdate"
              :value="data.item.amount"
              :item="data.item"
              champName="amount"
              type="text"
              :updateFunction="updateAcompteTh"
            ></EditableInput>
          </template>
        </b-table>
        <b-modal
          ref="deleteAcompteModal"
          id="deleteAcompteModal"
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal()"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Supprimer un acompte</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('deleteAcompteModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>

          <form
            @submit.prevent="handleSuprimerAcount"
            class="form-modal-custom-style"
          >
            <div class="mt-4 ml-3">
              <p>
                Êtes-vous sur de vouloir supprimer cette acompte ?
              </p>
            </div>
            <div class="actionModel">
              <div class="messageError">
                <div v-if="getErrorSuiviAcomptes" class="error">
                  <ul v-if="Array.isArray(getErrorSuiviAcomptes)">
                    <li
                      v-for="(e, index) in getErrorSuiviAcomptes"
                      :key="'error' + index"
                    >
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ getErrorSuiviAcomptes }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mt-1">
                <span>
                  Valider
                  <div v-if="getLoadingSuiviAcomptes" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </b-modal>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowSuiviAcompteTh"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import SelectComponent from '../../SelectComponent.vue';
import moment from 'moment';
export default {
  name: 'Suivi-des-acomptes',
  data() {
    return {
      page: 1,
      perPage: 20,
      search: null,
      user: { full_name: 'Tous', id: null },
      perPageList: [
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ],
      fields: [
        { key: 'actions', label: '', thClass: 'th-plus', tdClass: 'col-plus' },
        { key: 'numero_dossier', label: 'Numéro de dossier' },
        { key: 'nom', label: 'Nom client' },
        { key: 'prenom', label: 'Prénom client' },
        { key: 'commercial', label: 'Commercial' },
        { key: 'amount', label: 'Acompte' }
      ],
      accountToAdd: {
        numero_dossier: null,
        amount: null,
        user: null,
        nom: null,
        prenom: null,
        filiale: null,
        year: null
      },
      error: null,
      loading: false,
      AcompteToDelete: null,
      ListYear: []
    };
  },
  methods: {
    ...mapActions([
      'fetchAllAcomptesTh',
      'addAcompteTh',
      'updateAcompteTh',
      'deleteAcompteTh',
      'fetchUserAcompte',
      'getSettingFilialeTh'
    ]),
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      return 'ligneEnCourDeValidation';
    },
    resetModal() {
      this.accountToAdd = {
        numero_dossier: null,
        amount: null,
        user: null,
        nom: null,
        prenom: null,
        filiale: null,
        year: null
      };
      this.loading = false;
      this.error = null;
      this.AcompteToDelete;
      this.fetchUserAcompte({ all: 'no' });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    FilterFetchAllAcomptesTh() {
      this.fetchAllAcomptesTh({
        page: this.page,
        perPage: this.perPage,
        search: this.search,
        user: this.user
      });
    },
    changePerPage() {
      this.page = 1;
      this.FilterFetchAllAcomptesTh();
    },
    pagination(paginate) {
      this.page = paginate;
      this.FilterFetchAllAcomptesTh();
    },
    debounceInput: _.debounce(function(e) {
      this.search = e.target.value;
      this.FilterFetchAllAcomptesTh();
    }, 500),
    async handleSubmitAcompte() {
      if (this.accountToAdd.user == null) {
        this.error = 'User required';
        return;
      }
      this.loading = true;
      const response = await this.addAcompteTh(this.accountToAdd);
      if (response.succes) {
        this.hideModal('addAcompteModal');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    handleDeleteModal(item) {
      this.AcompteToDelete = item;
    },
    async handleSuprimerAcount() {
      const response = await this.deleteAcompteTh(this.AcompteToDelete);
      if (response) {
        this.fetchUserAcompte({ all: 'no' });
        this.hideModal('deleteAcompteModal');
      }
    },
    handleClickModalAcompte() {
      this.fetchUserAcompte({ all: 'yes' });
    },
    async handleChangeUser(payload) {
      if (this.user !== payload.value) {
        if (payload.value == null) {
          this.user = { full_name: 'Tous', id: null };
        } else {
          this.user = payload.value;
        }
        this.FilterFetchAllAcomptesTh();
      }
    }
  },
  computed: {
    ...mapGetters([
      'getLoadingSuiviAcomptes',
      'getErrorSuiviAcomptes',
      'getSuiviAcomptesTable',
      'getTotalRowSuiviAcompteTh',
      'getUserAcompte',
      'getloadingSelectUserAcount',
      'getSettingFilialesTh',
      'checkPermission',
      'checkPermission'
    ]),
    computedCantUpdate() {
      return this.checkPermission(this.$options.name);
    },
    computedGetUserTh() {
      return this.getUserAcompte;
    }
  },
  components: {
    Card: () => import('../../component/card.vue'),
    SelectComponent: SelectComponent,
    EditableInput: () => import('../../component/EditableInputTh.vue')
  },
  mounted() {
    this.fetchUserAcompte({ all: 'no' });
    var courantYear = moment().year();
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.getSettingFilialeTh();
    this.FilterFetchAllAcomptesTh();
  }
};
</script>

<style lang="scss" scoped>
.suivi-acomptes {
  margin: 0px;
  width: 100%;
  padding: 10px;
  display: block;
  height: calc(100vh - 60px);
  overflow: hidden;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .body-box-rapport {
    height: calc(100vh - 175px);
  }
  .search-input {
    height: 31px;
    width: 160px;
    padding: 5px 3px 5px 15px;
    border-radius: 0px 15px 15px 0px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid white;
    outline: none;
    color: #948f8f;
    font-size: 11px;
    background-color: #f9f9f9;

    &::placeholder {
      color: #d4d4d4;
    }
    // &:focus,
    // &:active {
    //   opacity: 1;
    // }
  }
  .trash-style {
    color: #dc3545;
    outline: none;
  }
}
.body-Suivi-acompte-th {
  margin-top: 5px;
  height: calc(100vh - 114px);
}
.tableSuiviAcompteTh {
  height: calc(100vh - 220px);
  max-height: calc(100vh - 151px);
  margin-bottom: 0px;
}
</style>
<style lang="scss">
.form-modal-custom-style .ModelCol .form-group .d-block {
  text-align: center;
  margin-left: 0px;
}
</style>
